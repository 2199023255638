<template>
  <section id="features" class="features">
    <div class="container" data-aos="fade-up">
      <header class="section-header">
        <h2>Features</h2>
        <p>A feature-packed API mocking solution</p>
      </header>

      <div class="row">
        <div class="col-lg-6">
          <img src="../../assets/img/features.png" class="img-fluid" alt="" />
        </div>

        <div class="col-lg-6 mt-5 mt-lg-0 d-flex">
          <div class="row align-self-center gy-4">
            <div class="col-md-6" data-aos="zoom-out" data-aos-delay="200">
              <div class="feature-box d-flex align-items-center">
                <i class="bi bi-check"></i>
                <h3>No-code API Builder</h3>
              </div>
            </div>

            <div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
              <div class="feature-box d-flex align-items-center">
                <i class="bi bi-check"></i>
                <h3>JavaScript API Editor</h3>
              </div>
            </div>

            <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
              <div class="feature-box d-flex align-items-center">
                <i class="bi bi-check"></i>
                <h3>JSON Data Store</h3>
              </div>
            </div>

            <div class="col-md-6" data-aos="zoom-out" data-aos-delay="500">
              <div class="feature-box d-flex align-items-center">
                <i class="bi bi-check"></i>
                <h3>Fake Record Generator</h3>
              </div>
            </div>

            <div class="col-md-6" data-aos="zoom-out" data-aos-delay="600">
              <div class="feature-box d-flex align-items-center">
                <i class="bi bi-check"></i>
                <h3>Flat File Creator</h3>
              </div>
            </div>

            <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
              <div class="feature-box d-flex align-items-center">
                <i class="bi bi-check"></i>
                <h3>Dark &amp; Light Modes</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- / row -->

      <!-- Feature Tabs -->
      <div class="row feture-tabs" data-aos="fade-up">
        <div class="col-lg-6">
          <h3>You're minutes away from your own mock development APIs</h3>

          <!-- Tabs -->
          <ul class="nav nav-pills mb-3">
            <li>
              <a class="nav-link active" data-bs-toggle="pill" href="#tab1"
                >APIs in Minutes</a
              >
            </li>
            <li>
              <a class="nav-link" data-bs-toggle="pill" href="#tab2"
                >Bring Your Own Data</a
              >
            </li>
            <li>
              <a class="nav-link" data-bs-toggle="pill" href="#tab3"
                >Github Integration</a
              >
            </li>
          </ul>
          <!-- End Tabs -->

          <!-- Tab Content -->
          <div class="tab-content">
            <div class="tab-pane fade show active text-start" id="tab1">
              <p>
                Two great ways to build your mock APIs: use our no-code API
                builder, or extend our capabilities with your own JavaScript.
              </p>
              <div class="d-flex align-items-center mb-2">
                <i class="bi bi-check2"></i>
                <h4>No-code API Builder</h4>
              </div>
              <p>
                With our no-code API builder, you can mock up an API in minutes
                with just a few clicks! Our API builder has built-in support for
                many standard API features such as outputting records, storing
                received data, filtering responses, and authenticating requests.
              </p>
              <div class="d-flex align-items-center mb-2">
                <i class="bi bi-check2"></i>
                <h4>Low-code JavaScript API Editor</h4>
              </div>
              <p>
                Need more control over your mock APIs? Write custom scripts in
                JavaScript to handle processing API requests.
              </p>
            </div>
            <!-- End Tab 1 Content -->

            <div class="tab-pane fade show text-start" id="tab2">
              <p>
                Combine your own data with fake generated data to custom fit
                mock APIs to your business case.
              </p>
              <div class="d-flex align-items-center mb-2">
                <i class="bi bi-check2"></i>
                <h4>Your own data</h4>
              </div>
              <p>
                Store your own JSON data for your API response. Return it as is
                or combine it with generated data. You can even update stored
                data with incoming API requests!
              </p>
              <div class="d-flex align-items-center mb-2">
                <i class="bi bi-check2"></i>
                <h4>Generate fake data</h4>
              </div>
              <p>
                Generate all the fake records you need on the fly! We support
                generating a huge selection of data types such as names,
                addresses, emails, phone numbers, business details, and more!
              </p>
            </div>
            <!-- End Tab 2 Content -->

            <div class="tab-pane fade show text-start" id="tab3">
              <p>
                Enable our Github integration to bring Git version control to
                your API Faker projects!
              </p>
              <div class="d-flex align-items-center mb-2">
                <i class="bi bi-check2"></i>
                <h4>Commit updates directly to Github</h4>
              </div>
              <p>
                Our Github integration lets you commit your project updates
                directly to a Github repo so you can track your changes and
                maintain a version history.
              </p>
            </div>
            <!-- End Tab 3 Content -->
          </div>
        </div>

        <div class="col-lg-6">
          <img src="../../assets/img/features-2.png" class="img-fluid" alt="" />
        </div>
      </div>
      <!-- End Feature Tabs -->

      <!-- Feature Icons -->
      <div class="row feature-icons" data-aos="fade-up">
        <h3>All the features you need</h3>

        <div class="col-lg-4">
          <div class="box" data-aos="fade-up" data-aos-delay="200">
            <div class="portfolio-wrap">
              <a
                :href="require('../../assets/img/guided-api-builder-large.png')"
                data-gallery="features"
                class="glightbox"
              >
                <img
                  src="../../assets/img/guided-api-builder.png"
                  class="img-fluid"
                  alt="No-code API Builder"
                />
              </a>
            </div>
            <h3>No-Code API Builder</h3>
            <p>
              Use our no-code API builder to mock APIs with just a few clicks
            </p>
          </div>
        </div>

        <div class="col-lg-4 mt-4 mt-lg-0">
          <div class="box" data-aos="fade-up" data-aos-delay="400">
            <div class="portfolio-wrap">
              <a
                :href="require('../../assets/img/script-api-editor-large.png')"
                data-gallery="features"
                class="glightbox"
              >
                <img
                  src="../../assets/img/script-api-editor.png"
                  class="img-fluid"
                  alt="Low-code API Editor"
                />
              </a>
            </div>
            <h3>Low-Code API Editor</h3>
            <p>
              Take control. Extend the capabilities of our mock APIs using your
              own JavaScript.
            </p>
          </div>
        </div>

        <div class="col-lg-4 mt-4 mt-lg-0">
          <div class="box" data-aos="fade-up" data-aos-delay="600">
            <div class="portfolio-wrap">
              <a
                :href="require('../../assets/img/api-tester-large.png')"
                data-gallery="features"
                class="glightbox"
              >
                <img
                  src="../../assets/img/api-tester.png"
                  class="img-fluid"
                  alt="API Tester"
                />
              </a>
            </div>
            <h3>Built-In API Testing Client</h3>
            <p>
              Test your mock APIs without ever leaving API Faker. No third-party
              tool needed.
            </p>
          </div>
        </div>
      </div>
      <!-- End Feature Icons -->
    </div>
  </section>
</template>

<script>
import GLightbox from "glightbox";
import "glightbox/dist/css/glightbox.min.css";

export default {
  name: "Features",
  created() {
    window.GLightbox = GLightbox;
    this.$nextTick().then(() => {
      /**
       * Initiate portfolio lightbox
       */
      GLightbox();
    });
  },
};
</script>

<style scoped>
.portfolio-lightbox {
  cursor: pointer;
}
</style>
