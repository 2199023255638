import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./assets/vendor/remixicon/remixicon.css";
import "./assets/css/style.css";

// /**
//  * Easy selector helper function
//  */
// window.select = (el, all = false) => {
//   el = el.trim();
//   if (all) {
//     return [...document.querySelectorAll(el)];
//   } else {
//     return document.querySelector(el);
//   }
// };

// /**
//  * Easy event listener function
//  */
// window.on = (type, el, listener, all = false) => {
//   if (all) {
//     window.select(el, all).forEach((e) => e.addEventListener(type, listener));
//   } else {
//     window.select(el, all).addEventListener(type, listener);
//   }
// };

// /**
//  * Easy on scroll event listener
//  */
// window.onscroll = (el, listener) => {
//   el.addEventListener("scroll", listener);
// };

// /**
//  * Scrolls to an element with header offset
//  */
// // eslint-disable-next-line no-unused-vars
// window.scrollto = (el) => {
//   let header = window.select("#header");
//   let offset = header.offsetHeight;

//   if (!header.classList.contains("header-scrolled")) {
//     offset -= 10;
//   }

//   let elementPos = window.select(el).offsetTop;
//   window.scrollTo({
//     top: elementPos - offset,
//     behavior: "smooth",
//   });
// };

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount("#app");
