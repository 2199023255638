<template>
  <footer id="footer" class="footer">
    <div class="footer-newsletter">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <h4>Our Newsletter</h4>
            <p>Subscribe to receive news and updates about API Faker</p>
          </div>
          <div class="col-lg-6 text-start">
            <form
              action="https://dev.us5.list-manage.com/subscribe/post?u=deb5b8072a53121bab0bb0d07&amp;id=2a97ea82ec"
              method="post"
            >
              <input type="email" name="EMAIL" placeholder="Email" />
              <input type="submit" value="Subscribe" />
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-top">
      <div class="container">
        <div class="row gy-4">
          <div class="col-lg-5 col-md-12 footer-info">
            <a href="index.html" class="logo d-flex align-items-center">
              <img src="../assets/img/logo.png" alt="API Faker" />
            </a>
            <p class="text-start">
              A feature-packed REST &amp; GraphQL API mocking solution. Built
              for developers, by developers.
            </p>
            <div class="social-links mt-3">
              <a href="#" class="twitter"><i class="bi bi-twitter"></i></a>
              <!-- <a href="#" class="facebook"><i class="bi bi-facebook"></i></a>
              <a href="#" class="instagram"
                ><i class="bi bi-instagram bx bxl-instagram"></i
              ></a> -->
              <a href="#" class="linkedin"
                ><i class="bi bi-linkedin bx bxl-linkedin"></i
              ></a>
            </div>
          </div>

          <div class="col-lg-2 col-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li><i class="bi bi-chevron-right"></i> <a href="#">Home</a></li>
              <li>
                <i class="bi bi-chevron-right"></i>
                <a href="https://app.apifaker.dev/terms">Terms of Service</a>
              </li>
              <li>
                <i class="bi bi-chevron-right"></i>
                <a href="https://app.apifaker.dev/privacy">Privacy Policy</a>
              </li>
              <li>
                <i class="bi bi-chevron-right"></i>
                <a href="https://app.apifaker.dev/support">Support</a>
              </li>
            </ul>
          </div>

          <div
            class="col-lg-3 col-md-12 footer-contact text-center text-md-start"
          >
            <h4>Contact Us</h4>
            <p>
              <strong>Sales:</strong> sales@apifaker.dev<br />
              <strong>Support:</strong> support@apifaker.dev<br />
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="copyright">
        &copy; {{ year }} <strong><span>API Faker</span></strong
        >. All Rights Reserved.
      </div>
      <div class="credits">
        <!-- All the links in the footer should remain intact. -->
        <!-- You can delete the links only if you purchased the pro version. -->
        <!-- Licensing information: https://bootstrapmade.com/license/ -->
        <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/flexstart-bootstrap-startup-template/ -->
        Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    year() {
      const dt = new Date();
      return dt.getFullYear();
    },
  },
};
</script>
